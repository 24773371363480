<template>
  <div class="c-page404">
    <div class="c-page404__img">
      <img src="@/assets/images/404.png" alt="">
    </div>
    <div class="c-page404__body">
      <!-- <div class="floating-support">
        <a href="javascript:void(0);" onclick="zE.activate({hideOnClose: true});">
          <img src="./img/icon-support.png" alt="support icon"> Support
        </a>
      </div> -->
      <div class="c-page404__body-content">
        <h1 class="c-page404__body-content-title">You're flying</h1>
        <h1 class="c-page404__body-content-title">in a <span class = "c-page404__body-content-bold">wrong direction!</span></h1>
        <h3 class="c-page404__body-content-bold c-page404__body-content-subtitle">Redirecting you back to homepage shortly. </h3>
        <p class="c-page404__body-content-description">If you are facing difficulties accessing a certain page, please email us at :</p>
        <p class = "c-page404__body-content-link"><a class="small white-color bold" href="mailto:ops@sparrowexchange.com">ops@sparrowexchange.com</a></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted(){
    this.timeoutpage()
  },
  methods: {
    timeoutpage(){
      setTimeout(function(){
        window.location = '/'
      },5000)
    }
  }
}
</script>
